import Question, { Answer } from '../../Question';
import { fetchBoardAuthorization, fetchBoardAuthorizationFile } from '../../../_state/application-form';

import FileField from '../FileField';
import { Link } from '@mui/material';
import styles from './styles';
import { useRecoilValue } from 'recoil';

function BoardAuthFiles() {
  const finalAuthorizationTemplateId = useRecoilValue(fetchBoardAuthorization('finalAuthorizationTemplateId'));

  return (
    <>
      <Question>
        Upload Board Resolution Authorizing the Sale of New Shares (<Link href='https://cdn.netcapital.com/pdfs/legal/board-resolution-2024.docx' sx={styles.Link}>download template</Link>)
        <Answer>
          <FileField usage='BOARD_AUTHORIZATION' state={fetchBoardAuthorizationFile} />
        </Answer>
      </Question>
      <Question>
        Final Authorization (<Link href={`${process.env.REACT_APP_WEB_HOST}/files/${finalAuthorizationTemplateId}/final-authorization.pdf`} target='_blank' rel='noopener noreferrer' sx={styles.Link}>download template</Link>)
        <Answer>
          <FileField usage='FINAL_AUTHORIZATION' state={fetchBoardAuthorizationFile} />
        </Answer>
      </Question>
    </>
  );
}

export default BoardAuthFiles;